import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { sha256 } from "js-sha256";
import axios from "axios";

import "./App.css";
import config from "./config";

const environments = {
  dev: "development",
  staging: "staging",
  prod: "production"
}

function getBlockchainUrl(trx: string) {
  const baseUrl = config.blockchainUrls[config.environment];
  const url = new URL(trx, baseUrl);
  return url.toString();
}

function App() {
  const [trx, setTrx] = useState(null);
  const [hash, setHash] = useState('');
  const [invalid, setInvalid] = useState(false);

  const handleChange = async (file: File) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const hashResult = sha256(arrayBuffer);

      if (hashResult) {
        // Get trx from hash
        setHash(hashResult);
        try {
          const url = new URL(`item/${hashResult}`, config.baseUrl);
          const res = await axios.get(url.toString());
          if (res.data.trx) {
            setInvalid(false);
            setTrx(res.data.trx);
          } else {
            setInvalidFile();
          }
        } catch (error) {
          setInvalidFile();
        }
      }else{
        setHash('');
        setInvalidFile();
      }
    } catch (error) {
      console.log("Reading file failed", error);
    }
  };

  const setInvalidFile = () => {
    setTrx(null);
    setInvalid(true);
  }

  return (
    <div className='App'>
      <header className='App-header'>
        <p>Upload your file to verify its authenticity</p>
        <FileUploader
          handleChange={handleChange}
          name='file'
        />

        {hash && (
          <p className='App-hash'>
            Resulting hash of the file: {hash}
          </p>
        )}

        {trx && (
          <>
            <p className='App-check'>File is valid!</p>

            <a
              className='App-link'
              rel="noreferrer"
              target='_blank'
              href={getBlockchainUrl(trx)}
            >
              Transaction: {trx}
            </a>
          </>
        )}

        {invalid && (
          <p className='App-error'>
            A trx corresponding to that file wasn't found.
          </p>
        )}
      </header>
    </div>
  );
}

export default App;
